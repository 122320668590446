import React, { useEffect, useRef } from 'react';
import { Avatar, Button, Image, Skeleton, Typography ,message as antMessage} from 'antd';
import {
  MessageOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import FlatList from 'flatlist-react/lib';
import ChatMap from './../Chat/ChatMap';
import useLocalStorage from './../../configs/localStorage';
import { useHookstate } from '@hookstate/core';
import chatStates from './chatStates';
import InfiniteScroll from 'react-infinite-scroll-component';
const typingMessage = require('./../../assets/icons/typing_animation.gif');

const { Text } = Typography;

const ChatMessage = ({ messages, isTyping = false , requestForChangeLocation,loadingForChangeLocation,onLoadMoreMessages,canNotLoadMore}) => {
  const flatListRef = useRef(null);
  const [auth] = useLocalStorage('auth', null);

  useEffect(() => {
    //scroll to bottom
    const chatMessages = document.getElementById('chat-messages');
    if (flatListRef.current && messages && chatMessages) {
      flatListRef.current.scrollTop = flatListRef.current.scrollHeight;
    }
  }, [messages]);
  return (


    <div
    id="scrollableDiv"
    style={{
      height: '99%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column-reverse',
    }}
  >
    <InfiniteScroll
      dataLength={messages.length}
      next={onLoadMoreMessages}
      style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
      hasMore={!canNotLoadMore}
      inverse={true}
      loader={
        canNotLoadMore ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{ color: 'grey' }}>No more messages</span>
          </div>
        ) : (
          <Skeleton
            avatar
            paragraph={{ rows: 1 }}
            active
            style={{
              // paddingLeft: '10px',
              paddingRight: '90px',
              width: '90%',
              alignSelf: 'center',
            }}
          />
        )
      }
      endMessage={
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ color: 'grey', marginBottom: 25 }}>
            No more messages
          </span>
        </div>
      }
      scrollableTarget="scrollableDiv"
    >
      
      {messages?.map((chatContent, index) => {
        return RenderItem(chatContent, index,messages, isTyping, auth, requestForChangeLocation,loadingForChangeLocation);
      })}
    </InfiniteScroll>
  </div>
    // <div
    //   ref={flatListRef}
    //   id="chat-messages"
    //   style={{
    //     width: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     overflowY: 'auto',
    //     height: '100%',
    //     padding: '10px',
    //   }}
    // >





    //   <FlatList
        
    //     list={messages}
    //     key={messages?._id}
    //     renderItem={(item, index) => RenderItem(item, index, messages, isTyping, auth, requestForChangeLocation,loadingForChangeLocation)}
    //     loadMoreItems={() => { alert("load more") }}
    //     hasMoreItems={true}
        
    //     //scrollToBottom
    //   />
    // </div>
  );
};
const RenderIsTyping = ({item, index, messages, isTyping,auth}) => {


  if (index == messages.length - 1) {
    return  isTyping ? (
      <div
      key={item?._id}
       style={{
         flexDirection:
           isTyping?.userType == 'agent' ? 'row-reverse' : 'row',
         display: 'flex',
       }}
     >
       <div
         style={{
           [isTyping?.userType == 'agent'
             ? 'marginRight'
             : 'marginLeft']: '66px',
 
           objectFit: 'cover',
           marginTop: '10px',
           marginBottom: '10px',
           backgroundColor:
             isTyping?.userType == 'agent' ? '#1677FF' : '#EEEEEE',
           color: isTyping?.userType == 'agent' ? '#fff' : '#000',
           borderRadius: '30px',
           padding: 10,
 
           // marginLeft: '10px',
         }}
       >
         {isTyping?.text && auth.user.isSuperAdmin ? (
           <span
             style={{
               wordBreak: 'break-all',
             }}
           >
             {isTyping.text}
           </span>
         ) : (
           ''
         )}
 
         <img
           style={{
             width: '45px',
             height: '20px',
             objectFit: 'cover',
             marginBottom: '-10px',
             color: 'red',
             borderRadius: '30px',
 
             // marginLeft: '10px',
           }}
           src={typingMessage}
         />
       </div>
       <div
         style={{
           width: '45px',
           height: '20px',
           objectFit: 'cover',
           marginBottom: '-10px',
 
           borderRadius: '30px',
 
           // marginLeft: '10px',
         }}
       ></div>
     </div>
   ) : null
         
   }
 };
const RenderItem = (item, index, messages, isTyping,auth , requestForChangeLocation,loadingForChangeLocation) => {
  const previousMessage = index<messages.length-1 > 0 ? messages[index + 1] : null;
  const isCurrentUser = item.messageSource === 'agent'; // Assuming 'agent' is the current user
  const isSystemMessage =
    item.messageSource === 'system' || item.messageSource === 'bot';
  const isSameDay =
    previousMessage &&
    moment(item.createdAt).isSame(previousMessage.createdAt, 'day');
  const showDate = !previousMessage || !isSameDay;
  const getProductName = product => {
    if (product) {
      if (typeof product.product_name === 'string') {
        return product.product_name;
      } else if (typeof product.product_name === 'object') {
        return product.product_name.en;
      } else if (
        product.product_name_object &&
        Object.keys(product.product_name_object).length
      ) {
        return product.product_name_object.en;
      }
      return product.product_id;
    }
    return '';
  };
  return (
    <div key={item?._id}>
      {/* <Text>{item?._id}</Text> */}
      {showDate && (
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <Text type="secondary">
            {moment(item.createdAt).format('DD/MM/YYYY')}
          </Text>
        </div>
      )}
   {item.chatType === 'issueCard'?
       (
        <div
          style={{
            alignSelf:"flex-start",
            marginTop: 10,
            marginBottom: 10,
            border: '1px solid #000',
            padding: '10px',
            width: '60%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          }}
        >
          <p
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: '15px',
              borderBottom: '1px dashed #000',
            }}
          >
            {item.chatMessage}
          </p>
          {!!item.metaData?.issue?.products && _.map(JSON.parse(item.metaData.issue.products), (item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  gap: '10px',
                  padding: '10px',
                  borderRadius: '10px',
                  alignItems: 'center',
                }}
              >
                <p style={{ fontWeight: 'bold' }}>{item.quantity}x</p>

                <Image
                src={item?.product_image_url}
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'red',
                    borderRadius: '10px',
                  }}
                />
                {/* <p style={{ fontWeight: 'bold' }}>{item?.product_name['en']}</p> */}
                <p style={{ fontWeight: 'bold' }}>{
                  getProductName(item)
                      }</p>
              </div>
            );
          })}
          <div
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: '15px',
              borderBottom: '1px dashed #000',
            }}
          ></div>
          {!!(!!item?.metaData?.issue?.images || !!item?.metaData?.issue?.text) && <p
            style={{
              color: 'gray',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            Photo And description
          </p>}
          <div>
            {!!item?.metaData?.issue?.images && JSON.parse(item.metaData.issue.images)?.map(image =>{
              return <Image src={image} alt="issue image" width={100} height={100}/>
            })}
          </div>
          <p>
            {item?.metaData?.issue?.text}
          </p>
        </div>
      ):  <Message
          message={item}
      isCurrentUser={isCurrentUser}
          isSystemMessage={isSystemMessage}
          requestForChangeLocation={requestForChangeLocation}
          loadingForChangeLocation={loadingForChangeLocation}   />
    }
    
      <RenderIsTyping item={item} index={index} messages={messages} isTyping={isTyping} auth={auth}/>
    </div>
  );
};

const Message = ({ message, isCurrentUser, isSystemMessage,loadingForChangeLocation ,requestForChangeLocation}) => {
  const messageContainerStyle = {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: isCurrentUser
      ? 'flex-end'
      : isSystemMessage
      ? 'center'
      : 'flex-start',
  };

  const messageContentStyle = {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: isSystemMessage
      ? '#F9E3E2'
      : isCurrentUser
      ? '#4A90E2'
      : '#F1F4F9',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };

  const messageTextStyle = {
    color: isSystemMessage ? '#333333' : isCurrentUser ? '#ffffff' : 'black',
    fontSize: '14px',
    fontWeight: isSystemMessage ? 'normal' : isCurrentUser ? 'Bold' : 'normal',
  };

  const messageTimeStyle = {
    marginTop: '5px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
    alignSelf: 'flex-end',
  };
  const selected_chatroom = useHookstate(
    chatStates.selectedChatRoomState.selected_chatroom,
  );
  const renderMessageContent = () => {

    if (message.chatType === 'image') {
      return (
        <Image
          src={message.chatMessage}
          alt="Image"
          style={{ maxWidth: '20%', borderRadius: '10px' }}
        />
      );
    } else if (message.chatType === 'coordinate') {
      return (
        <div
        style={{
          width: '240px',
          height: '260px',
        }}
      >
        <ChatMap chat={message} />
        <Button
          style={{
            width: '100%',
          }}
          type="primary"
          loading={loadingForChangeLocation}
          onClick={async() => {
            if (message?.chatMessage) {
           try {
            await  requestForChangeLocation({
               statusId: 19,
               orderId: selected_chatroom.get().roomRef,
               orderLongitude:
                 message.chatMessage?.split(',')[1],
               orderLatitude: message.chatMessage?.split(',')[0],
             });
              antMessage.success('Updated location');
           } catch (error) {
            antMessage.error(error?.response?.data?.msg||"Error while changing order status");
           }
            }
          }}
        >
          Change location
        </Button>
      </div>
      );
    } else {
      return <Text style={messageTextStyle}>{message.chatMessage}</Text>;
    }
  };

  return (
    <div style={messageContainerStyle}>
      <div style={messageContentStyle}>
        {!isSystemMessage && (
          <div
            style={{
              marginBottom: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              size="small"
              src={message.createdByImage}
              style={{ marginRight: '5px' }}
            />
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {message.createdByName}
            </Text>
          </div>
        )}
        {isSystemMessage && (
          <MessageOutlined
            style={{ marginRight: '5px', fontSize: '16px', color: '#333333' }}
          />
        )}
        {renderMessageContent()}
        <div style={messageTimeStyle}>
          {moment(message.createdAt).format('hh:mm A')}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
