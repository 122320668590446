import React, { useCallback, useEffect, useRef, useState } from 'react';
import FlatList from 'flatlist-react/lib';
import { Button, Col, Input, Row, Tag, Tooltip, Typography } from 'antd';
import ChatRoomListItem from './ChatRoomListItem';
import chatStates from './chatStates';
import _ from 'lodash';
import AgentSelect from '../Chat/AgentSelect';
import RemoteSelectForm from '../utility/RemoteSelectForm';
import { useAxiosGetChat } from '../../configs/axios';
import { LoadingOutlined } from '@ant-design/icons';
const CUSTOMER_CHATS = 'customer_agent,customer_general';
const { Text } = Typography;

const { Search } = Input;

function ChatRoomList({
  height,
  chatRooms,
  triggerLastAction,
  activeFilters,
  onSearch,
  fetchData,
  setSelectedMessageTab,
  getTasks,
  runTheInterval,
  showFavorites,
  setShowFavorites,onFavoriteClick
}) {
  const [filteredChatRooms, setFilteredChatRooms] = useState(chatRooms);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const timer = useRef(null);
  useEffect(() => {
    if (
      activeFilters.roomType ||
      activeFilters.roomName ||
      activeFilters.roomLanguage ||
      activeFilters.issueTags ||
      activeFilters.roomAssignedToName
    ) {
      let filteredRooms = chatRooms;
      if (activeFilters.roomType) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.roomType === activeFilters.roomType;
        });
      }
      if (activeFilters.roomName) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.roomName === activeFilters.roomName;
        });
      }
      if (activeFilters.roomLanguage) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.roomLanguage === activeFilters.roomLanguage;
        });
      }
      if (activeFilters.issueTags) {
        filteredRooms = filteredRooms.filter((room) =>
          // room.issueTags = [{name: 'tag1'}, {name: 'tag2'}]
          room?.issueTags?.find((tag) => tag.name === activeFilters.issueTags),
        );
      }
      if (activeFilters.roomAssignedToName) {
        filteredRooms = filteredRooms.filter(
          (room) =>
            room.roomAssignedToName === activeFilters.roomAssignedToName,
        );
      }
      const activeFiltersCount = Object.keys(activeFilters).filter(
        (key) => activeFilters[key],
      ).length;
      setActiveFiltersCount(activeFiltersCount);
      setFilteredChatRooms(filteredRooms);
    } else {
      setActiveFiltersCount(0);
      setFilteredChatRooms(chatRooms);
    }
  }, [activeFilters, chatRooms]);

  const onChatSelect = useCallback(
    (chatRoom) => {
      setSelectedMessageTab('1');
      chatStates.selectedChatRoomState.merge({
        selected_id: chatRoom._id,
        selected_chatroom: chatRoom,
      });
      triggerLastAction();
    },
    [
      chatStates?.selectedChatRoomState?.selected_id,
      chatStates?.selectedChatRoomState?.selected_chatroom,
    ],
  );

  const isSelected = useCallback(
    (chatRoom) => {
      return (
        chatStates.selectedChatRoomState.selected_id.get() === chatRoom._id
      );
    },
    [
      chatStates?.selectedChatRoomState?.selected_id,
      chatStates?.selectedChatRoomState?.selected_chatroom,
    ],
  );
  const refreshIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
  const [isLoadingRef, setIsLoadingRef] = useState(false);
  const debounceFetchData = async () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(async () => {
      await fetchData();
      await getTasks();

      setIsLoadingRef(false);
    }, 700);
  };

  const handleClick = () => {
    setIsLoadingRef(true);
    runTheInterval();

    debounceFetchData();
  };

  const renderItem = useCallback(
    (item, index) => {
      return (
        <ChatRoomListItem
          key={item._id}
          item={item}
          onSelect={() => onChatSelect(item)}
          isSelected={isSelected(item)}
        />
      );
    },
    [onChatSelect, isSelected],
  );

  return (
    <div
      style={{
        width: '100%',
        height: height,
        maxHeight: height,
        minHeight: height,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #E9EDF5',
        overflowY: 'auto',
        // backgroundColor: '#E9EDF5',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <Row
        style={{
          width: '96%',
          maxWidth: '100%',
          marginBottom: '10px',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Col  >
          <Search
            placeholder="Search chat rooms"
            onSearch={(value) => onSearch(value)}
            allowClear
            style={{ margin: 0,width: '150px'  }}

          />
        </Col>
        <Col>
        <Tooltip
              title={
                showFavorites ? 'Showing Favorites Chats' : 'Show All Chat'
              }
            >
              <div
                style={{
                  width: 23,
                  height: 23,
                  cursor: 'pointer',
                }}
                onClick={onFavoriteClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  id="bookmark-full"
                >
                  <path
                    fill={showFavorites ? '#09B231' : '#000'}
                    strokeWidth={showFavorites ? '1' : ''}
                    stroke={showFavorites ? '#09B231' : '#000'}
                    d="M18,2H6A1,1,0,0,0,5,3V21a1,1,0,0,0,1.65.76L12,17.27l5.29,4.44A1,1,0,0,0,18,22a.84.84,0,0,0,.38-.08A1,1,0,0,0,19,21V3A1,1,0,0,0,18,2ZM17,18.86,12.64,15.2a1,1,0,0,0-1.28,0L7,18.86V4H17Z"
                  />
                </svg>
              </div>
            </Tooltip></Col>
        <Col
          
          style={{
            textAlign: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#f0f5ff',
              borderRadius: '8px',
              display: 'inline-block',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            
          
            <Button
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#2f54eb',
                padding: '8px 16px',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={handleClick}
              icon={isLoadingRef ? refreshIcon : null}
            >
              {isLoadingRef
                ? 'Refreshing'
                : `Total Chats: ${filteredChatRooms.length}`}
            </Button>
          </div>
        </Col>

        {/* Agent Select */}
        <Col  style={{ textAlign: 'right', paddingLeft: '10px' }}>
          <RemoteSelectForm
            nameForm={'agent'}
            fetchAxios={useAxiosGetChat}
            endpoint={'/agents'}
            labelOption={'userName'}
            valueOption={'_id'}
            allowClear={true}
            onValueChange={({ object }) => {
              onSearch(undefined, object?.value);
            }}
            style={{ margin: 0,width: '130px'  }}
          />
        </Col>
      </Row>

      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #E9EDF5',
          overflowY: 'auto',
          // backgroundColor: '',
          paddingTop: '10px',
        }}
      >
        {filteredChatRooms.length > 0 ? (
          <FlatList list={filteredChatRooms} renderItem={renderItem} />
        ) : (
          <Text>No chat rooms</Text>
        )}
      </div>
    </div>
  );
}

export default ChatRoomList;
