import { Button, Col, Row, Spin, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import DispatchModeMap from './DispatchModeMap';
import CSModeMap from './CSModeMap';
import { useAxiosGet } from '../../../configs/axios';

const OrderMapModalComponent = (props) => {
  const {
    data: orderMapData,
    loading: orderMapLoading,
    error: orderMapError,
    request: getOrderMap,
  } = useAxiosGet('/couriers/map');
  const {
    status,
    setStatus,
    getAllOrders,
    ordersSearchFilters,
    selectedOrder,
  } = props;

  const [dispatchMode, setDispatchMode] = useState(false);

  const [driversLocations, setDriversLocations] = useState([
    {
      latitude: 36.171,
      longitude: 44.031,
      order: 0,
      online: true,
    },
    {
      latitude: 36.171,
      longitude: 44.03,
      order: 0,
      online: false,
    },
    {
      latitude: 36.17,
      longitude: 44.03,
      order: 1,
      online: true,
    },
    {
      latitude: 36.17,
      longitude: 44.033,
      order: 2,
      online: true,
    },
    {
      latitude: 36.17,
      longitude: 44.032,
      order: 4,
      online: true,
    },
  ]);

  useEffect(() => {
    getOrderMap();
  }, []);

  return (
    <Modal
      title="Track Order"
      destroyOnClose
      getContainer={false}
      open={status}
      onOk={() => {
        setStatus(false);
      }}
     footer={dispatchMode?undefined:null}
      onCancel={() => {
        setStatus(false);
      }}
      centered
      width={'100%'}
      afterClose={() => {
        setChangeDropOffLocationEnabled(false);
        setDispatchMode(false);
      }}
    >
      <Row>
        <Col>
          <Button
            type="primary"
            style={{
              borderRadius: '60px',
              marginBottom: '10px',
            }}
            onClick={() => setDispatchMode(!dispatchMode)}
          >
            Switch to {dispatchMode ? 'CS' : 'Dispatch'} mode
          </Button>
        </Col>
        {dispatchMode && orderMapData ? (
          <DispatchModeMap
            orderMapData={orderMapData}
            orderMapLoading={orderMapLoading}
            orderMapError={orderMapError}
            closeModal={() => setStatus(false)}
            getAllOrders={getAllOrders}
            ordersSearchFilters={ordersSearchFilters}
            selectedOrder={selectedOrder}
            />
          ) : orderMapData ? (
            <CSModeMap
            closeModal={() => setStatus(false)}
            getAllOrders={getAllOrders}
            ordersSearchFilters={ordersSearchFilters}
            orderMapData={orderMapData}
            orderMapLoading={orderMapLoading}
            orderMapError={orderMapError}
            selectedOrder={selectedOrder}
          />
        ) : (
          <div>
            <br />
            <br />
            <br />
            <Spin size="large" />
          </div>
        )}
      </Row>
    </Modal>
  );
};

export default OrderMapModalComponent;
