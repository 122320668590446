import { ConfigProvider, Layout, theme } from 'antd';
import React, { Suspense, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { Provider } from 'react-redux';
import _ from 'lodash';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import routes from './routes';
import SidebarContent from './components/layout/SidebarContent';
import HeaderContent from './components/layout/HeaderContent';
import Login from './pages/Login';
import Order from './pages/Order';
import useLocalStorage from './configs/localStorage';
import store from './store';
import ErrorBoundary from './ErrorBoundary';
import { darkModeLocalConst } from './configs/constants';

mapboxgl.workerClass = 
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const { Header, Sider, Content } = Layout;

function App() {
  const [auth, setAuth] = useLocalStorage('auth', null);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const colors = ['#e4343e', '#cb8b1e', '#52C41A', '#B37FEB', '#1677ff'];
  const [colorLocalStorage, setColorLocalStorage] = useLocalStorage(
    darkModeLocalConst,
    null
  );
  const [color, setColor] = useState(
    colorLocalStorage?.color ? colorLocalStorage?.color : colors[4]
  );
  const [collapsed, setCollapsed] = React.useState(true);

  if (!auth?.user?.role?.permissions && auth != null) {
    setAuth(null);
  }

  const pers = auth?.user.role?.permissions?.map((per) => per.name) || [];
  _.remove(routes, (route) => !_.includes(pers, route.path));

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY}
      scriptProps={{ 
        async: true, 
        defer: true,
        appendTo: 'body',
        nonce: undefined 
      }}
    >
      <ErrorBoundary>
        <ConfigProvider
          theme={{
            algorithm: colorLocalStorage?.darkMode ? darkAlgorithm : defaultAlgorithm,
            token: colorLocalStorage?.darkMode
              ? { colorPrimary: color, colorBgBase: '#0e0e0e' }
              : { colorPrimary: color }
          }}
        >
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                {/* Public routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/order-details" element={<Order />} />

                {/* Protected layout */}
                <Route
                  path="/*"
                  element={
                    <Layout style={{ minHeight: '100vh' }}>
                      <Sider
                        collapsed={collapsed}
                        style={{
                          background: !colorLocalStorage?.darkMode ? '#fff' : null,
                          height: '100vh',
                          position: 'fixed',
                          overflow: 'auto',
                          boxShadow: '0.4px 0.4px 20px #bfbdb644',
                        }}
                      >
                        <SidebarContent />
                      </Sider>
                      <Layout>
                        <Header
                          style={{
                            background: !colorLocalStorage?.darkMode ? '#fff' : null,
                            position: 'fixed',
                            zIndex: 1000,
                            width: collapsed ? 'calc(100% - 79px)' : 'calc(100% - 200px)',
                            marginLeft: collapsed ? '79px' : '200px',
                            transition: 'all 0.2s',
                            paddingInline: '10px',
                          }}
                        >
                          <HeaderContent toggleSidebar={() => setCollapsed(!collapsed)} />
                        </Header>
                        <Content
                          style={{
                            background: !colorLocalStorage?.darkMode ? '#F8FBFF' : null,
                            marginLeft: collapsed ? '79px' : '200px',
                            padding: '0px 10px',
                            marginTop: '64px',
                          }}
                        >
                          {/* Nested routes */}
                          <Routes>
                            {routes.map((route) => (
                              <Route
                                key={route.key}
                                path={route.path}
                                element={
                                  <Suspense fallback={<>...</>}>
                                    <route.component />
                                  </Suspense>
                                }
                              />
                            ))}
                          </Routes>
                        </Content>
                      </Layout>
                    </Layout>
                  }
                />
              </Routes>
            </BrowserRouter>
          </Provider>
        </ConfigProvider>
      </ErrorBoundary>
    </GoogleReCaptchaProvider>
  );
}

export default App;