import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAxiosPostChat } from '../../configs/axios';
import useLocalStorage from '../../configs/localStorage';
import { useHookstate } from '@hookstate/core';
import chatStates from '../Chatv2/chatStates';

const BookmarkChat = () => {

   const selected_chatroom = useHookstate(
      chatStates.selectedChatRoomState.selected_chatroom,
    );
  const [auth] = useLocalStorage('auth', null);
  const userID = auth?.user?._id;

  const { request: resolveChatroom } = useAxiosPostChat('/chats/markRoom');
  const isRoomBookmarked = () => {
    return selected_chatroom?.roomFavoriteBy.get()?.includes(userID);
  }; 
  const handleBookmark = async () => {
    let updatedBookmarkedRooms = Array.isArray(selected_chatroom?.get().roomFavoriteBy)
    ? selected_chatroom?.get().roomFavoriteBy
    : [];
    if (isRoomBookmarked()) {
      selected_chatroom.set((prev) => ({
        ...prev,
            roomFavoriteBy: prev.roomFavoriteBy.filter(
              (id) => id !== userID,
            )
          }));
      updatedBookmarkedRooms = updatedBookmarkedRooms.filter(
        (id) => id !== userID,
      );
    } else {

        selected_chatroom.set((prev) => ({
      ...prev,
          roomFavoriteBy: [userID]
        }));
    }
    await resolveChatroom({
      roomId:selected_chatroom?._id.get(),
      chatSource: selected_chatroom?.roomType.get(),
    });
  };

  return (
    <Tooltip
      title={isRoomBookmarked() ? 'Remove Bookmark' : 'Bookmark this Chat'}
    >
      <div
        style={{
          width: 23,
          height: 23,
          cursor: 'pointer',
        }}
        onClick={handleBookmark}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="bookmark-full"
        >
          <path
            fill={isRoomBookmarked() ? '#09B231' : '#000'}
            strokeWidth={isRoomBookmarked() ? '1' : ''}
            stroke={isRoomBookmarked() ? '#09B231' : '#000'}
            d="M18,2H6A1,1,0,0,0,5,3V21a1,1,0,0,0,1.65.76L12,17.27l5.29,4.44A1,1,0,0,0,18,22a.84.84,0,0,0,.38-.08A1,1,0,0,0,19,21V3A1,1,0,0,0,18,2ZM17,18.86,12.64,15.2a1,1,0,0,0-1.28,0L7,18.86V4H17Z"
          />
        </svg>
      </div>
    </Tooltip>
  );
};

export default BookmarkChat;
