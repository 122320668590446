/* eslint-disable no-restricted-globals */
import { Button, Form, Input } from 'antd';
import Wave from 'react-wavify';
import React, { useRef, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useLocalStorage from '../configs/localStorage';
import { useAxiosPost } from '../configs/axios';
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../configs/firebase';

let firebaseApp;
let messaging;
try {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
} catch (error) {
  console.error('Firebase init error:', error);
}

const LoginForm = () => {
  const [form] = Form.useForm();
  const fcmTokenRef = useRef();
  const [auth, setAuth] = useLocalStorage('auth', null);
  const [showV2Captcha, setShowV2Captcha] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaError, setCaptchaError] = useState(null);
  const [usedRecaptchaVersion, setUsedRecaptchaVersion] = useState(null);

  const {
    request: loginRequest,
    loading: loginLoading,
    error: loginError,
  } = useAxiosPost('/auth/login');

  useEffect(() => {
    const initServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
        );
        const token = await getToken(messaging, {
          serviceWorkerRegistration: registration,
        });
      } catch (error) {
        console.error('Service Worker Error:', error);
      }
    };

    if ('serviceWorker' in navigator) {
      initServiceWorker();
    }
  }, []);

  const handleLogin = async (values) => {
    try {
      let recaptchaToken = null;
      let recaptchaVersion = usedRecaptchaVersion;

      // Always attempt to get v3 token first
      if (!showV2Captcha) {
        if (!executeRecaptcha) throw new Error('reCAPTCHA not initialized');
        recaptchaToken = await executeRecaptcha('login');
        recaptchaVersion = 'v3';
        setUsedRecaptchaVersion('v3');
      } else if (showV2Captcha) {
        // If v2 is required, use its token
        if (!captchaToken) {
          setCaptchaError(
            'Please complete the captcha to prove you are not a robot 🤖',
          );
          return;
        }
        recaptchaToken = captchaToken;
      }

      const fcmToken = await getFCMToken();
      const loginData = await loginRequest({
        username: values.username,
        password: values.password,
        fcmToken,
        recaptchaToken,
        recaptchaVersion,
      });

      // Reset state on success
      setFailedAttempts(0);
      setShowV2Captcha(false);
      // setCaptchaToken(null);
      // setCaptchaError(null);
      // setUsedRecaptchaVersion(null);

      setAuth({
        token: loginData.data.token,
        user: loginData.data.user,
        fcmToken,
      });
      location.replace('/');
    } catch (error) {
      const attempts = failedAttempts + 1;
      setFailedAttempts(attempts);

      // Show v2 after 2 failed attempt
      if (attempts > 2) {
        setShowV2Captcha(true);
        form.setFieldsValue({ password: '' });
      }

      console.error('Login failed:', error);
    }
  };

  const getFCMToken = async () => {
    try {
      if (fcmTokenRef.current) return fcmTokenRef.current;
      const permission = await Notification.requestPermission();
      return permission === 'granted' ? await getToken(messaging) : null;
    } catch (error) {
      console.error('FCM Error:', error);
      return null;
    }
  };

  if (auth) {
    location.replace('/');
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgb(0,100,192)',
      }}
    >
      <div
        style={{
          position: 'relative',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          alt="lezzoo"
          src="https://assets-global.website-files.com/63cfc6ce2af1e25afcbf098c/6475c9e73a99deaf4e9536fb_Lezzoo%20Logo.png"
          style={{
            position: 'absolute',
            top: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '450px',
            maxWidth: '90%',
            zIndex: 1,
          }}
        />

        {/* Login Form Container */}
        <div
          style={{
            backgroundColor: 'white',
            padding: '40px',
            borderRadius: '20px',
            width: '650px',
            maxWidth: '90%',
            minHeight: '500px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
            boxShadow: '0 10px 30px rgba(0,0,0,0.15)',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <div>
            <h1
              style={{
                fontSize: '2.5rem',
                fontWeight: '700',
                marginBottom: '0.5rem',
                color: '#1890ff',
              }}
            >
              Welcome Back
            </h1>
            <p
              style={{
                color: 'rgba(0,0,0,0.6)',
                fontSize: '1.1rem',
                marginBottom: '2rem',
              }}
            >
              Enter your credentials
            </p>
          </div>

          <Form
            layout="vertical"
            style={{
              width: '100%',
              marginTop: '20px',
            }}
            onFinish={handleLogin}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: 'Please enter your username' },
              ]}
            >
              <Input
                size="large"
                placeholder="Username"
                style={{ padding: '12px 15px', fontSize: '16px' }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Password"
                style={{ padding: '12px 15px', fontSize: '16px' }}
              />
            </Form.Item>

            {showV2Captcha && (
              <Form.Item
                style={{
                  margin: '25px 0',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    transform: 'scale(1.1)',
                    transformOrigin: 'center',
                  }}
                >
                  <ReCAPTCHA
                    theme="light"
                    size="normal"
                    sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                    onChange={(token) => {
                      setCaptchaToken(token);
                      setUsedRecaptchaVersion('v2');
                    }}
                    onExpired={() => setCaptchaToken(null)}
                  />
                </div>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loginLoading}
                block
                style={{
                  height: '48px',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>

          {loginError && (
            <div
              style={{
                color: '#ff4d4f',
                marginTop: '15px',
                fontSize: '14px',
              }}
            >
              {loginError.response?.data?.msg || 'Authentication failed'}
            </div>
          )}

          {captchaError && (
            <div
              style={{
                color: '#ff4d4f',
                marginTop: '15px',
                fontSize: '18px',
                fontWeight: 'Bold',
              }}
            >
              {captchaError}
            </div>
          )}
        </div>

        {/* Wave Effects */}
        <Wave
          fill="white"
          style={{
            height: '20%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: 'none',
            zIndex: 1,
          }}
          options={{
            amplitude: 40,
            speed: 0.2,
            points: 8,
          }}
        />
        <Wave
          fill="white"
          style={{
            height: '25%',
            opacity: 0.5,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: 'none',
            zIndex: 0,
          }}
          options={{
            amplitude: 40,
            speed: 0.2,
            points: 6,
          }}
        />
      </div>
    </div>
  );
};

export default LoginForm;
