import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Table,
  Button,
  DatePicker,
  Input,
  Space,
  message,
  Tooltip,
} from 'antd';
import { useAxiosGet } from '../../configs/axios';
import moment from 'moment';
import debounceFx from '../../functions/debounceFx';
import useLocalStorage from '../../configs/localStorage';

const CustomerCompensationsDrawer = ({ customerId }) => {
  const [auth] = useLocalStorage('auth', null);
  const isCompensationTransactionEnabled =
    auth?.user?.compensationTransactionEnabled;
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const [searchQuery, setSearchQuery] = useState({
    customer_id: customerId,
    ref_id: '',
    from: '',
    to: '',
  });

  const {
    data,
    loading,
    request: fetchTransactions,
  } = useAxiosGet('/customers/customer-compensations');

  const handleSearch = (values) => {
    setSearchQuery((prev) => ({ ...prev, ...values }));
    fetchTransactions({ ...searchQuery, ...values });
  };

  const debouncedSearch = debounceFx(handleSearch, 500);

  const columns = [
    {
      title: 'Index',
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Transaction ID',
      dataIndex: 'lezzoopay_transaction_id',
      key: 'lezzoopay_transaction_id',
    },
    {
      title: 'Order ID',
      dataIndex: 'ref_id',
      key: 'ref_id',
      render: (ref_id) => (
        <Tooltip
          title={
            <Button
              type="primary"
              onClick={() =>
                window.open(
                  `${window.location.origin}/order-details?oid=${ref_id}`,
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            >
              Show Order Details
            </Button>
          }
        >
          <p
            style={{ cursor: 'pointer', color: '#1890ff' }}
            onClick={() => {
              navigator.clipboard.writeText(ref_id);
              message.success('Copied to clipboard');
            }}
          >
            {ref_id}
          </p>
        </Tooltip>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `${amount.toLocaleString()} IQD`,
    },
    {
      title: 'Type',
      dataIndex: 'transaction_type',
      key: 'transaction_type',
    },
    {
      title: 'Description',
      dataIndex: 'transaction_description',
      key: 'transaction_description',
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  useEffect(() => {
    if (open && customerId) {
      fetchTransactions({ customer_id: customerId });
    }
  }, [open, customerId]);

  return (
    <>
      {isCompensationTransactionEnabled && (
        <Button
          style={{
            marginTop: '10px',
          }}
          type="primary"
          onClick={() => setOpen(true)}
          block
        >
          Show Compensations
        </Button>
      )}
      <Drawer
        title="Customer Compensations"
        placement="right"
        width={1000}
        onClose={onClose}
        open={open}
      >
        <Space style={{ marginBottom: 16 }} size="middle">
          <Input
            placeholder="Search by Order ID"
            onChange={(e) => debouncedSearch({ ref_id: e.target.value })}
            style={{ width: 200 }}
          />
          <Space>
            <DatePicker
              placeholder="From Date"
              onChange={(date) => {
                handleSearch({
                  ...searchQuery,
                  from: date ? date.format('YYYY-MM-DD') : '',
                });
              }}
            />
            <DatePicker
              placeholder="To Date"
              onChange={(date) => {
                handleSearch({
                  ...searchQuery,
                  to: date ? date.format('YYYY-MM-DD') : '',
                });
              }}
            />
          </Space>
        </Space>

        <Table
          columns={columns}
          dataSource={data || []}
          loading={loading}
          rowKey="lezzoopay_transaction_id"
          pagination={false}
        />
      </Drawer>
    </>
  );
};

export default CustomerCompensationsDrawer;
