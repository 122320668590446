import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import useLocalStorage from '../../configs/localStorage';
import { useHookstate } from '@hookstate/core';
import {
  useAxiosGetChat,
  useAxiosPostChat,
  useAxiosPut,
} from '../../configs/axios';
import { Button, Input, Skeleton, Tabs, Tag, Typography, Upload } from 'antd';
import chatStates from './chatStates';
import ChatMessage from './Message';
import {
  CloseCircleOutlined,
  CloseOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import ChatScriptTab from '../Chat/ChatScriptTab';
import { darkModeLocalConst } from '../../configs/constants';
import BookmarkChat from '../Chat/Bookmark';
const { Search } = Input;
const format = 'hh:mm A';
const { Text } = Typography;
function MessageList({
  socketRef,
  height,
  setSelectedMessageTab,
  selectedMessageTab,
}) {
  const {
    request: requestForChangeLocation,
    loading: loadingForChangeLocation,
  } = useAxiosPut('/orders/status');
  const [auth] = useLocalStorage('auth', null);
  const [message, setOnMessage] = useState('');
  const [chatroom, setChatroom] = useState(null);
  const [chatroomMessages, setChatroomMessages] = useState([]);
  const [colorLocalStorage] = useLocalStorage(darkModeLocalConst, null);
  const messagePerPage = 25;
  const selected_chatroom = useHookstate(
    chatStates.selectedChatRoomState.selected_chatroom,
  );
  const chatMessagesRef = useRef(null); // Ref for the chat messages container

  const { loading: chatroomMessagesLoading, request: chatroomMessagesRequest } =
    useAxiosGetChat('/chats/messages');
  const { request: sendMessageRequest, loading: sendMessageIsLoading } =
    useAxiosPostChat('/chats');
  const { request: resolveChatroom } = useAxiosPostChat('/chats/resolveChat');
  const { request: uploadImage } = useAxiosPostChat('/chats/image');
const [canNotLoadMore,setCanNotLoadMore]  = useState(false);
  const onMessageReceived = useCallback((data) => {
    const room = selected_chatroom.get();
    if (room?._id === data.chatRoomId) {
      const uniqueKey = Date.now();
      setChatroomMessages((prev) => [ { ...data, _id: uniqueKey },...prev]);
    }
  }, []);

  // Scroll to bottom whenever chat messages change
  useLayoutEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chatroomMessages]);

  const sendMessage = async (message, messageType = 'text') => {
    const room = selected_chatroom?.get?.();

    if (!room || message.length == 0 || sendMessageIsLoading) {
      return;
    }
    try {
      setOnMessage('');
      const requestBody = {
        message,
        roomRef: room.roomRef,
        messageType,
        roomId: room._id,
        chatSource: room.roomType,
        messageSource: 'agent',
      };
      if (room.status != 'opened') {
        //update chatRoom status to opened
        selected_chatroom.set((prev) => ({
          ...prev,
          status: 'opened',
        }));
      }
      //setChatroomMessages((prev) => [...prev, newMessage]);
      await sendMessageRequest(requestBody);
    } catch (error) {
      console.log('Error sending message', error);
    }
  };

  useEffect(() => {
    const room = selected_chatroom.get();

    const roomChannel = `${room?.roomType}:${room?.roomRef}`;

    if (socketRef?.socket) {
      const socket = socketRef.socket;

      if (socket?.connected) {
        socket.emit('join', { room: roomChannel });

        socket.on('sent-message', onMessageReceived);
      }
    }

    return () => {
      if (socketRef?.socket) {
        const socket = socketRef.socket;
        socket.off('sent-message', onMessageReceived);
        socket.emit('left_room', {
          room: roomChannel,
          userId: auth?.user?.id,
        });
      }
    };
  }, [selected_chatroom?._id?.get?.(), socketRef?.socket]);

  const fetchChatMessages = async () => {
    const room = selected_chatroom.get();
    if (!room?._id) return;
    setCanNotLoadMore(false);
    // Fetch chat messages
    const data = await chatroomMessagesRequest({
      chatSource: room.roomType,
      roomType: room.roomType,
      roomRef: room.roomRef,
      roomId: room?._id,
      page: 0,
      limit: messagePerPage,
    });

    setChatroom({ ...room, _id: data.roomId });
    setChatroomMessages(data.messages);
    if(data.messages.length < 25){
      setCanNotLoadMore(true);
    }
  };

  useEffect(() => {
    fetchChatMessages();
  }, [selected_chatroom?._id?.get?.()]);

  const renderChatSource = () => {
    let name = '';
    const roomType = selected_chatroom?.roomType.get?.();
    switch (roomType) {
      case 'customer_agent':
      case 'customer_general':
        name = 'Customer';
        break;
      case 'merchant_agent':
        name = 'Merchant';
        break;
      case 'orders':
      case 'couriers':
        name = 'Driver';
        break;

      default:
        name = roomType;
        break;
    }
    return (
      <h4
        style={{
          flex: 1,
        }}
      >{`  (${name})  `}</h4>
    );
  };
const isLoadMore = useRef(false);

  const onLoadMoreMessages = async (chatroom) => {
    try {
      // messagePage.current = messagePage.current + 1;
      if (isLoadMore.current) return;
      isLoadMore.current = true;

      // alert('load more');
      const data = await chatroomMessagesRequest({
        chatSource: selected_chatroom.chatStartSource.get(),
        roomType: selected_chatroom.roomType.get(),
        roomRef: selected_chatroom.roomRef.get(),
        roomId: selected_chatroom._id.get(),
        // page: messagePage.current,
        limitMessages: true,
        limit: messagePerPage,
        messageId: chatroomMessages[0]?._id,

      });
     
      if (data.messages.length < 25) {
        setCanNotLoadMore(true);
      }
      setChatroomMessages([...chatroomMessages, ...data.messages]);
      isLoadMore.current = false;

    } catch (e) {

      isLoadMore.current = false;
    }
  };

  return (
    selected_chatroom?._id?.get?.() && (
      <div
        style={{
          width: '100%',
          height: height,
          maxHeight: height,
          minHeight: height,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            backgroundColor: '#6244671',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4
            style={{
              paddingRight: '10px',
            }}
          >
            {selected_chatroom?.get?.()?.roomName}{' '}
          </h4>

          {renderChatSource()}
          <BookmarkChat
            chatSource={selected_chatroom.chatStartSource.get()}
            roomId={selected_chatroom?._id.get()}
            setSelectedChatroom={selected_chatroom.get()}
            selectedChatroom={selected_chatroom.get()}
          />
          <Button
            style={{
              marginRight: '10px',
              marginLeft: '10px',
            }}
            type="text"
            icon={<CloseCircleOutlined />}
            onClick={() => {
              selected_chatroom.set({
                roomType: '',
                roomRef: '',
                roomType: '',
              });
            }}
          />
          
          <Button
            type="primary"
            onClick={async () => {
              try {
                const selectedChatroom = selected_chatroom.get();
                // akar
                await resolveChatroom({
                  roomType: selectedChatroom.roomType,
                  roomRef: selectedChatroom.roomRef,
                  chatSource: selectedChatroom.roomType,
                });
              } catch (error) {
                console.log('====================================');
                console.log(error);
                console.log('====================================');
              }
            }}
          >
            Resolve
          </Button>
        
        </div>
        <div
          ref={chatMessagesRef}
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            overflow: 'hidden', // Prevent parent container from scrolling
            backgroundColor: '#ffffff28', // Consistent background
            height: '100%', // Full height for the container
          }}
        >
          {/* Display Issue Tags */}
          <div
            style={{
              backgroundColor: 'transparent', // Match the overall background
              padding: '5px',
              marginBottom: '10px',
            }}
          >
            {selected_chatroom?.get?.()?.issueTags?.map((tag) => (
              <Tag color={tag.color} key={tag.name}>
                {tag.name}
              </Tag>
            ))}
          </div>

          <div
            style={{
              backgroundColor: '#ffffff28',
              padding: '10px 0',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              zIndex: 10,
              position: 'sticky',
              top: 0,
            }}
          >
            <Tabs
              style={{
                backgroundColor: 'transparent',
              }}
              onChange={(e) => {
                setSelectedMessageTab(e);
              }}
              activeKey={selectedMessageTab}
              items={[
                {
                  key: '1',
                  label: 'Messages',
                },
                {
                  key: '2',
                  label: 'Script',
                },
              ]}
            />
          </div>

          {/* Tab Content (Scrollable) */}
          <div
            style={{
              flex: 1,
              overflowY: 'auto',
              padding: '10px',
              backgroundColor: 'transparent', // Maintain consistent background
            }}
          >
            {chatroomMessagesLoading&& chatroomMessages?.length ==0? (
              <Skeleton active />
            ) : chatroomMessages?.length > 0 ? (
              selectedMessageTab === '1' ? (
                <ChatMessage
                  id="chat-messages"
                  messages={chatroomMessages}
                  requestForChangeLocation={requestForChangeLocation}
                    loadingForChangeLocation={loadingForChangeLocation}
                    onLoadMoreMessages={onLoadMoreMessages}
                    canNotLoadMore={canNotLoadMore}
                />
              ) : (
                <ChatScriptTab
                  setMessage={setOnMessage}
                  setSelectedTab={setSelectedMessageTab}
                  source={selected_chatroom?.get?.()?.roomType}
                  selectedChatroom={selected_chatroom?.get?.()}
                  colorLocalStorage={colorLocalStorage}
                />
              )
            ) : null}
          </div>
        </div>

        <div
          style={{
            padding: '10px 15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#eaf4fc', // Soft blue for the container background
            borderRadius: '8px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            gap: '12px',
          }}
        >
          <Upload
            customRequest={async (options) => {
              const { onSuccess, onError, file, onProgress } = options;
              try {
                const formData = new FormData();
                formData.append('image', file);
                const imageUrl = await uploadImage(formData);
                await sendMessage(imageUrl, 'image');
                onSuccess('Ok');
              } catch (err) {
                onError({ err });
              }
            }}
          >
            <PaperClipOutlined
              style={{
                fontSize: '22px',
                cursor: 'pointer',
                color: '#023e7d', // Accent color for the icon
                transition: 'color 0.3s ease',
                marginTop: '2px',
                marginRight: '10px',
              }}
              onMouseEnter={(e) => (e.target.style.color = '#1abc9c')} // Hover effect
              onMouseLeave={(e) => (e.target.style.color = '#3498db')}
            />
          </Upload>

          <Input.TextArea
            style={{
              flex: 1,
              border: '1px solid #dcdfe6',
              borderRadius: '6px',
              padding: '8px 12px',
              fontSize: '14px',
              resize: 'none',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
            }}
            placeholder="Type a message..."
            value={message}
            onChange={(e) => setOnMessage(e.target.value)}
            rows={2}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onPressEnter={(e) => {
              if (e.shiftKey) {
                // Shift + Enter adds a new line
                e.preventDefault();
                setOnMessage((prevMessage) => prevMessage + '\n');
              } else {
                // Enter sends the message
                e.preventDefault();
                sendMessage(message);
              }
            }}
          />

          <Button
            style={{
              backgroundColor: '#023e7d',
              color: '#fff',
              border: 'none',
              borderRadius: '6px',
              padding: '8px 16px',
              fontSize: '14px',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'background-color 0.3s ease',
              userSelect: 'none', // Prevent text selection
            }}
            onClick={() => sendMessage(message)} // Send message on button click
          >
            Send
          </Button>
        </div>
      </div>
    )
  );
}

export default MessageList;
